import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const [listOfPosts, setListOfPosts] = useState([]);

  useEffect(() => {
    axios.get("http://localhost:3001/posts").then((response) => {
      setListOfPosts(response.data);
      //console.log(response.data);
    });
  }, []); //merge o data cand se face refresh

  return (
    <>
      {" "}
      <div>Lista de posts</div>
      {listOfPosts.map((item) => {
        return (
          <div class="card text-center mt-4 w-50" key={item.id}>
            <div class="card-header text-danger fw-bold">POST</div>
            <div class="card-body">
              <h5 class="card-title">{item.title}</h5>
              <p class="card-text">{item.postText}</p>
            </div>
            <div class="card-footer text-muted">{item.username}</div>
          </div>
        );
      })}
    </>
  );
}

export default App;
